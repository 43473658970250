/* #signUp-tab {
    display: none;
} */
/* [data-amplify-authenticator] [data-amplify-router] {
    border-style: none;
} */


[data-amplify-authenticator] {
    --amplify-components-authenticator-form-padding: 1em;
    --amplify-components-authenticator-router-border-style: none;
    --amplify-components-authenticator-router-box-shadow: none;

    
    /* --amplify-colors-background-primary: var(--amplify-colors-neutral-90);
    --amplify-colors-background-secondary: var(--amplify-colors-neutral-100);
    --amplify-colors-primary-10: var(--amplify-colors-teal-100);
    --amplify-colors-primary-80: var(--amplify-colors-teal-40);
    --amplify-colors-primary-90: var(--amplify-colors-teal-20);
    --amplify-colors-primary-100: var(--amplify-colors-teal-10);
    --amplify-colors-font-interactive: var(--amplify-colors-white);
    --amplify-components-tabs-item-active-color: var(--amplify-colors-white);
    --amplify-components-tabs-item-focus-color: var(--amplify-colors-white);
    --amplify-components-tabs-item-hover-color: var(--amplify-colors-orange); */
}

/* [data-amplify-authenticator]{
    --amplify-components-authenticator-router-border-style: none;
    --amplify-components-authenticator-router-box-shadow: none;
    --amplify-components-authenticator-form-padding: 1.5rem;

    --amplify-components-button-primary-background-color: var(--dl-color-primary);
    --amplify-components-button-primary-hover-background-color: var(--dl-color-primary-hover);

    --amplify-components-button-primary-active-background-color: var(--dl-color-primary-hover);
    --amplify-components-button-primary-focus-background-color: var(--dl-color-primary-hover);

    --amplify-components-button-link-hover-background-color: #e8efff; 
    --amplify-components-button-link-hover-color: var(--dl-color-primary);
    --amplify-components-button-link-active-background-color: #e8efff;
    --amplify-components-button-link-active-color: var(--dl-color-primary);
    --amplify-components-button-link-focus-background-color: #e8efff; 
    --amplify-components-button-link-focus-color: var(--dl-color-primary-hover);

    --amplify-colors-border-focus: var(--dl-color-primary);
    --amplify-colors-border-pressed: var(--dl-color-primary);
    --amplify-internal-button-focus-box-shadow: 0px 0px 0px 2px var(--dl-color-primary);
    --amplify-components-fieldcontrol-focus-box-shadow: 0px 0px 0px 2px var(--dl-color-primary);

    --amplify-components-tabs-item-active-color: var(--dl-color-primary);
    --amplify-components-tabs-item-hover-color: var(--dl-color-primary-hover);
    --amplify-components-tabs-item-active-border-color: var(--dl-color-primary);

    --amplify-components-button-link-color: var(--dl-color-primary);

    --amplify-internal-button-background-color: #e8efff; 
    --amplify-components-button-hover-color: var(--amplify-colors-font-primary);
}

.amplify-button:focus {
    box-shadow: 0px 0px 0px 2px var(--dl-color-primary-hover) !important;
}

.amplify-tabs__item--active:hover {
    border-color: var(--dl-color-primary-hover);
} */
