/* html, body { height: 100%; } */
/* body {
  background:radial-gradient(ellipse at center, rgba(255,254,234,1) 0%, rgba(255,254,234,1) 35%, #B7E8EB 100%);
  overflow: hidden;
} */

.ocean-container {
  position: relative;
  height: 325px;
  overflow: hidden;
}

.ocean { 
    height: 5%;
    width:100%;
    position:absolute;
    bottom:0;
    left:0;
    background: #015871;
    overflow-x: clip;
  }
  
  .wave {
    /* background: url(https://s3-us-west-2.amazonaws.com/s.cdpn.io/85486/wave.svg) repeat-x;  */
    background: url('../../assets/media/main-wave.svg');
    position: absolute;
    top: -198px;
    width: 6400px;
    height: 198px;
    animation: wave 10s cubic-bezier( 0.36, 0.45, 0.63, 0.53) infinite;
    transform: translate3d(0, 0, 0);
  }


  
  .wave:nth-of-type(2) {
    top: -175px;
    animation: wave 11s cubic-bezier( 0.36, 0.45, 0.63, 0.53) -.125s infinite, swell 7s ease -1.25s infinite;
    opacity: 1;
  }
  
  @keyframes wave {
    0% {
      margin-left: 0;
    }
    100% {
      margin-left: -1600px;
    }
  }

  
  @keyframes swell {
    0%, 100% {
      transform: translate3d(0,-25px,0);
    }
    50% {
      transform: translate3d(0,5px,0);
    }
  }

  .home-body{
    height: 300px;
  }

  .home-footer{
    height: 200px;
    background-color: var(--dl-dark-blue)
  }

  .animated-wave{
    background: url('../../assets/media/animated-wave.svg');
  }

  .waves-container{
    height: 5%;
    width:100%;
    position:absolute;
    bottom:0;
    left:0;
    background: #015871;
    overflow-x: clip;
  }

  .banner{
    height: 400px;
    position: relative;
  }

  .wave-1{
    background: url('../../assets/media/wave-1.svg');
    position: absolute;
    bottom: 0;
    height: 225px;
    background-repeat: no-repeat;
  }