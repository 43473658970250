body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

:root {
  --dl-color-primary: #6d82f9;
  --dl-color-primary-hover: #3a3fa0;
  --dl-color-primary-loading: #9CA4F4;

  --dl-midnight-blue: #145DA0;
  --dl-dark-blue: #0C2D48;
  --dl-blue: #2E8BC0;
  --dl-baby-blue: #BFD7ED;

  /* --amplify-colors-primary-10: var(--dl-baby-blue); */
  --amplify-colors-primary-80: var(--dl-midnight-blue);
  --amplify-colors-primary-90: var(--dl-dark-blue);
  --amplify-colors-primary-100: var(--dl-midnight-blue);
  /*
  
  --amplify-colors-font-interactive: var(--amplify-colors-font-interactive);
  --amplify-components-tabs-item-active-color: var(--amplify-components-tabs-item-active-color);
  --amplify-components-tabs-item-focus-color: var(--amplify-components-tabs-item-focus-color);
  --amplify-components-tabs-item-hover-color: var(--amplify-components-tabs-item-hover-color); */
}